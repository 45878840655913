<template>
  <v-layout column>
    <PredefinedMedicalCodesFilter
      v-if="visibleSearch"
      :search.sync="search"
      :paired.sync="paired"
      :field="field"
    ></PredefinedMedicalCodesFilter>
    <v-divider v-show="search" class="mx-4 my-2"></v-divider>
    <v-treeview
      v-show="!search"
      class="mr-2 ml-1 default-style"
      :items="internalFields"
      item-text="label"
      item-key="altId"
      :load-children="fetchChildren"
      selection-type="independent"
      loading-icon="fas fa-spinner"
      open-on-click
      transition
      expand-icon=""
    >
      <template v-slot:label="{ item, open }">
        <PredefinedPairDelegate
          :item="item"
          :open="open"
          :paired.sync="internalPaired"
          :opened.sync="opened"
        ></PredefinedPairDelegate>
      </template>
    </v-treeview>
  </v-layout>
</template>

<script>
import MedicalcodesService from "@/services/medicalcodes.service";
import CRUDMixin from "@/mixins/CRUDMixin";
import PredefinedService from "@/services/predefined.service";
import { FIELD_TYPES, MEDICAL_CODES_FIELDS } from "@/constants/patientcard/fields/types";
import _ from "lodash";

export default {
  mixins: [CRUDMixin],
  props: {
    value: {},
    predefined: {},
    field: {},
    paired: {},
    cardType: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      search: null,
      codes: [],
      opened: [],
      pagination: {
        rowsPerPage: -1,
        page: 1,
        min: 1,
      },
    };
  },
  computed: {
    internalFields: {
      get() {
        return [this.field];
      },
      set(value) {
        this.$emit("update:field", value);
      },
    },
    internalPaired: {
      get() {
        return this.paired;
      },
      set(value) {
        this.$emit("update:paired", value);
      },
    },
    visibleSearch() {
      return this.opened.includes(this.field.name) && MEDICAL_CODES_FIELDS.includes(this.field.type);
    },
  },
  components: {
    DefaultLabel: () => import("@/components/text/DefaultLabel"),
    DefaultSubText: () => import("@/components/text/DefaultSubText"),
    ConfirmDialog: () => import("@/components/popups/ConfirmDialog"),
    FavoriteMedicalCodeDelegate: () =>
      import("@/components/delegates/FavoriteMedicalCodeDelegate"),
    FavoriteMedicalCodesFilter: () =>
      import("@/components/lists/FavoriteMedicalCodesFilter"),
    PredefinedPairDelegate: () =>
      import("@/components/delegates/predefined/PredefinedPairDelegate"),
    LabelTextField: () => import("@/components/LabelTextField"),
    PredefinedMedicalCodesFilter: () =>
      import("@/components/lists/PredefinedMedicalCodesFilter"),
  },
  methods: {
    async fetchChildren(item) {
      this.beforeRequest();
      if (FIELD_TYPES.TEXT_FIELD == item.type) {
        return PredefinedService.getPredefinedRecords(item.name, {
          card_type: this.cardType,
          rowsPerPage: -1,
          page: 1,
        })
          .then((reply) => {
            let items = reply.results;
            items.forEach((element) => {
              this.$set(element, "checkable", true);
              this.$set(element, "selectable", true);
              this.$set(element, "label", element.title);
              this.$set(element, "category", FIELD_TYPES.TEXT_FIELD);
              this.$set(element, "altId", element.category + "_" + element.id);
            });
            this.$set(item, "children", items);
            this.reset();
          })
          .catch((error) => {
            console.log("ERROR", error, error.reply);
            this.handleError("Coś poszło nie tak.");
          });
      } else if (MEDICAL_CODES_FIELDS.includes(item.type)) {
        return MedicalcodesService.getCategories(
          {
            rowsPerPage: -1,
            page: 1,
          },
          item.name.toLowerCase()
        )
          .then((reply) => {
            let items = reply.results;
            items.forEach((element) => {
              this.$set(element, "checkable", true);
              this.$set(element, "children", []);
              this.$set(element, "name", item.name.toLowerCase());
              this.$set(
                element,
                "label",
                `<b>${element.code}</b> - ${element.text}`
              );
              this.$set(element, "category", item.type);
              this.$set(element, "altId", element.category + "_" + element.id);
            });
            this.$set(item, "children", items);
            this.reset();
          })
          .catch((error) => {
            console.log("ERROR", error, error.response);
            this.handleError("Coś poszło nie tak.");
          });
      } else {
        return MedicalcodesService.getChildren(
          {
            rowsPerPage: -1,
            page: 1,
          },
          item.name,
          item.code
        )
          .then((reply) => {
            let items = reply.results;
            items.forEach((element) => {
              this.$set(
                element,
                "label",
                `<b>${element.code}</b> - ${element.text}`
              );
              this.$set(element, "name", item.name);
              this.$set(element, "checkable", true);
              if (!element.selectable) {
                this.$set(element, "children", []);
              }
              this.$set(element, "category", this.determineCodeType(item.name));
              this.$set(element, "altId", element.category + "_" + element.id);
            });
            this.$set(item, "children", items);
            this.reset();
          })
          .catch((error) => {
            console.log("ERROR", error, error.response);
            this.handleError("Coś poszło nie tak.");
          });
      }
    },
    determineCodeType(name) {
      switch (name) {
        case "icd9":
          return FIELD_TYPES.ICD9_CODES_FIELD;
        case "icd10":
          return FIELD_TYPES.ICD10_CODES_FIELD;
        case "icf":
          return FIELD_TYPES.ICF_CODES_FIELD;
        default:
          return "";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.default-style ::v-deep .v-treeview-node__root:hover::before {
  opacity: 0 !important;
}
</style>